@import '../../assets/scss/variables/variables.scss';

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.body {
  flex-direction: row;
}

.content {
  padding: 20px;
}

.header {
  padding: 20px;
  height: 80px;
  display: flex;
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $greyeb;
  .logoWrap {
    display: flex;
    align-items: center;
  }
  .logo {
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: 80px;
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
  }
}

.sider {
  background-color: #001529;
  min-width: 261px !important;
  ul {
    background-color: #001529;
    border: none;
    color: white;
    li {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.container {
  background-color: #f0f2f5;
}
