body {
  background: #f0f2f5;
  > iframe {
    display: none !important;
  }
}

#root {
  min-height: 100%;
}

.w-100 {
  width: 100%;
}

.mr-5 {
  margin-right: 5rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-5 {
  margin-bottom: 5rem;
}

.d-flex {
  display: flex;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: end;
}
.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.text-cente {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.color-success {
  color: #4caf50;
}

.color-danger {
  color: #f44336;
}

.color-warning {
  color: #ffc107;
}

.btn {
  &-danger {
    background-color: #f44336;
    border-color: #f44336;
    color: white;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: #f44336;
      color: white;
      outline: none;
      border-color: #f44336;
    }
  }

  &-success {
    background-color: #4caf50;
    border-color: #4caf50;
    color: white;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: #4caf50;
      color: white;
      outline: none;
      border-color: #4caf50;
    }
  }
  &-warning {
    background-color: #ffc107;
    border-color: #ffc107;
    color: white;

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: #ffc107;
      color: white;
      outline: none;
      border-color: #ffc107;
    }
  }
}

.button-group {
  &.left {
    button {
      margin-right: 5px;
    }
  }
  &.right {
    button {
      margin-left: 5px;
    }
  }
}
.note {
  font-size: 13px;
  color: #999;
}

.ant-descriptions-item-label {
  font-weight: bold;
}

.ant-menu-sub.ant-menu-inline {
  background-color: #001529;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #ececec;
  font-weight: 500;
}
