.ant-upload {
  &.ant-upload-drag {
    height: auto;
    margin-bottom: 15px;
  }
}

.ant-upload-list {
  margin-bottom: 20px;
}

.ant-input-number.ant-input-number-sm {
  width: 100%;
}
.ant-input-number-input-wrap {
  padding: 4px 11px !important;
}
.ant-input-number-sm input {
  padding: 0 7px 0 0 !important;
}
