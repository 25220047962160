.ant-input-number.ant-input-number-sm {
  width: 100%;
}

.ant-input-number-input-wrap {
  padding: 0px;
}

.ant-input-number-sm input {
  padding: 0 7px 0 0 !important;
}

.ant-input-number {
  width: 100%;
}
