.editor-root {
  width: 90%;
  display: flex;
  font-family: Roboto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.editor-label {
  display: block;
  font-size: 20px;
  font-weight: 500;
  margin: 50px 0 25px;
}
.editor-label-high {
  display: block;
  font-size: 20px;
  font-weight: 500;
  margin: 50px 0 90px;
}
.editor-subLabel {
  margin-bottom: 20px;
}
.editor-editorSection {
  display: flex;
  justify-content: space-between;
}
.editor-wrapper {
  width: 100% !important;
  display: block !important;
  margin-bottom: 25px !important;
}
.editor-wrapper-medium {
  width: 650px !important;
  display: block !important;
  margin-bottom: 50px !important;
  height: 300px !important;
}
.editor-editor {
  height: 275px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.editor-content {
  border: 1px solid #f1f1f1;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  border-radius: 2px;
  resize: none;
  height: 200px;
}
.editor-toolbar-absolute {
  position: absolute !important;
  top: -40px !important;
  width: 550px !important;
  border-radius: 3px !important;
  background: #f3f3f3 !important;
  border: 1px solid #e3e3e3 !important;
}
.editor-toolbar-absolute-high {
  position: absolute !important;
  top: -80px !important;
  width: 550px !important;
  border-radius: 3px !important;
  background: #f3f3f3 !important;
  border: 1px solid #e3e3e3 !important;
}
.editor-editor-plain {
  height: 80% !important;
  border-width: 0px !important;
}
.editor-editor-embedded {
  height: 90% !important;
  border-width: 0px !important;
}
.editor-wrapper-relative {
  display: block !important;
  position: relative !important;
  margin-bottom: 25px !important;
  height: 200px !important;
}
.editor-wrapper-relative-long {
  width: 715px !important;
  display: block !important;
  position: relative !important;
  margin-bottom: 25px !important;
  height: 450px !important;
}
.bordered-option-classname {
  border: 1px solid #e3e3e3 !important;
}
.editor-toolbar-absolute > div:not(:last-of-type) {
  border-right: 2px solid #e3e3e3;
  padding-right: 5px;
}
.editor-toolbar-absolute > div:not(:first-of-type) {
  padding-left: 5px;
}
.editor-toolbar-custom {
  border-color: rgb(0, 47, 126) !important;
  background-color: #9ebef5 !important;
}
.editor-editor-custom {
  height: 200px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  height: 75% !important;
  border-radius: 2px !important;
  border-color: rgb(0, 47, 126) !important;
}
.editor-option-custom {
  width: 15px !important;
  height: 15px !important;
  border-color: rgb(0, 47, 126) !important;
  color: rgb(0, 47, 126);
}
.editor-option-custom-wide {
  width: 125px !important;
  height: 25px !important;
  border-color: rgb(0, 47, 126) !important;
  color: rgb(0, 47, 126) !important;
}
.editor-option-custom-medium {
  height: 25px !important;
  border-color: rgb(0, 47, 126) !important;
  color: rgb(0, 47, 126) !important;
}
.editor-dropdown-custom {
  border-color: rgb(0, 47, 126) !important;
}
.editor-popup-custom {
  border-color: rgb(0, 47, 126) !important;
  padding: 5px !important;
}
.editor-popup-custom input {
  border-color: rgb(0, 47, 126) !important;
}
.editor-popup-custom button {
  border-color: rgb(0, 47, 126) !important;
}
.editor-section-wrapper {
  display: flex;
}
.editor-editor-wrapper {
  width: 60%;
}
.editor-section {
  margin: 50px 30px 20px;
  width: 95%;
}
.ReactCodeMirror {
  width: 38%;
  margin-left: 2%;
  border: 1px solid #e4e4e4;
  height: 400px;
}
.CodeMirror {
  height: 400px !important;
}
.editor-custom-option {
  width: 26px;
  height: 26px;
  border: 1px solid #f1f1f1;
  text-align: center;
  padding-right: 5px;
  padding-bottom: 2px;
  cursor: pointer;
}
